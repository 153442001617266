import React from "react";
import { Title2, Title, Section, ParallexHeader } from "../../utils/";
import styled from "styled-components";
// import Service from "./Service";
import SlideServices from "./slider/SlideServices";
// import WorkFlow from "../04-workflow/WorkFlow";
// import { StaticQuery, graphql } from "gatsby";
// import SlidesTab from "../utils/SlidesTab";
// import CardScale from "./CardScale";
import BlockchainList from "./bchainList/BlockchainList";
import FAQSection from "./FAQSection";

/**************************************/
//          ServicesBody Class
/**************************************/
export default function ServicesBody({
  servicesTitle,
  dsectionTitle,
  dsectionSubtitle,
  dsFlowTitle,
  dsFlowDescription,
  dsectionTitle2,
  dsectionSubtitle2
}) {
  const {
    sectionTitle,
    sectionSubtitle,
    sFlowTitle,
    sFlowDescription,
    sectionTitle2,
    sectionSubtitle2
  } = servicesTitle.node;
  return (
    <div>
      <Section>
        <ServicesBodyWrapper>
          <Title
            title={sectionTitle || dsectionTitle}
            message={sectionSubtitle.sectionSubtitle || dsectionSubtitle}
          />
        </ServicesBodyWrapper>
        <SlideServices />
      </Section>
      <ParallexHeader>
        <Section>
          <Title2
            title={sFlowTitle || dsFlowTitle}
            message={sFlowDescription.sFlowDescription || dsFlowDescription}
            titleColor="black-color"
            messageColor="grey-color"
          />
        </Section>
      </ParallexHeader>
      {/* <WorkFlow /> */}
      {/* <CardScale /> */}

      <Section>
        <Title
          title={sectionTitle2 || dsectionTitle2}
          message={sectionSubtitle2.sectionSubtitle2 || dsectionSubtitle2}
        />
        <BlockchainList />
      </Section>
      <FAQSection />
    </div>
  );
}

/**************************************/
//       ServicesBodyWrapper SC
/**************************************/
const ServicesBodyWrapper = styled.div`
  width: 80vw;
  margin: 0rem auto;
  #pid1 #pid2 {
    display: none;
  }
`;

ServicesBody.defaultProps = {
  dsectionTitle: "Our Services_",
  dsectionSubtitle:
    "Since every application has its unique use-case, we start every solution with a strategic assessment to give our customers a better understanding of the potential areas that could give the best return on their investment_",
  dsFlowTitle: "Our Development Process_",
  dsFlowDescription:
    "Ensure team is always working on something important. Smaller chunks for bigger deliverables_",
  dsectionTitle2: "What Blockchain services we offer?_",
  dsectionSubtitle2:
    "Whether you want to integrate, migrate or build your Dapp from the ground up. We provide end-to-end blockchain services. Our certified blockchain developers and architects will help you to integrate your application with blockchain_"
};

/**************************************/
//          Graphql Constants
/**************************************/
// const SERVICE = graphql`
//   {
//     serviceCards: allContentfulServiceCard(
//       sort: { fields: [index], order: ASC }
//     ) {
//       edges {
//         node {
//           index
//           title
//           description {
//             description
//           }
//           alignLeft
//           iconTitle
//         }
//       }
//     }
//   }
// `;
