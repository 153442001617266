/**
 * used in Services page to list the blockchain services
 * responsive Card icon in circle, subtitle then description
 * responsive using materializecss, we read the icon library name and the icon name fron
 * the headless css (Contenful)
 * By icon lib i mean, lib for react icon like fa ("react-icons/fa")
 */
import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { styles } from "../../../utils/";
// import * as MaterialDesign from "react-icons/fa";

function handleReactIcon(libName) {
  let MaterialDesign = "";
  if (libName === "fa") {
    MaterialDesign = require("react-icons/fa");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "md") {
    MaterialDesign = require("react-icons/md");
  } else if (libName === "ti") {
    MaterialDesign = require("react-icons/ti");
  }
  //  else if (libName === "fi") {
  //   MaterialDesign = require("react-icons/fi");
  // } else if (libName === "go") {
  //   MaterialDesign = require("react-icons/go");
  // } else if (libName === "gi") {
  //   MaterialDesign = require("react-icons/gi");
  // } else if (libName === "wi") {
  //   MaterialDesign = require("react-icons/wi");
  // } else if (libName === "di") {
  //   MaterialDesign = require("react-icons/di");
  // }
  return MaterialDesign;
}

function BlockchainList() {
  // const iconName = "FaTwitter";
  // let tst = "fa";
  // let MaterialDesign = handleReactIcon("fa");
  // const MaterialDesign = require("react-icons/" + { iconName });
  // const mdIcon = MaterialDesign[iconName];

  return (
    <BlockchainListWrapper>
      <div className="container-wide features">
        <div className="features-container">
          <div className="row">
            <StaticQuery
              query={BCSERVICE}
              render={data => {
                const bcServicesDetail = data.bsServicesData.edges;

                return (
                  <div>
                    {bcServicesDetail.map((solution, index) => {
                      let iconName = solution.node.iconTitle;
                      let iconLibName = solution.node.subtitle;
                      let MaterialDesign = handleReactIcon(iconLibName);
                      let mdIcon = MaterialDesign[iconName];
                      return (
                        <div className="col s12 m6 l4" key={index}>
                          <div className="feature-icon">
                            {React.createElement(mdIcon, {
                              className: "material-icons"
                            })}
                          </div>
                          <div className="feature-title">
                            {solution.node.title}
                          </div>
                          <div className="feature-description">
                            {solution.node.description.description}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </BlockchainListWrapper>
  );
}

/**************************************/
//          Graphql Constants
/**************************************/
const BCSERVICE = graphql`
  {
    bsServicesData: allContentfulBlockchainServices(
      sort: { fields: [index], order: ASC }
    ) {
      edges {
        node {
          index
          title
          subtitle
          description {
            description
          }
          iconTitle
        }
      }
    }
  }
`;

/**************************************/
//       ServicesBodyWrapper SC
/**************************************/
const BlockchainListWrapper = styled.div`
  .features {
    text-align: center;
    color: #f62d51;
    margin-top: 30px;
    margin-bottom: 40px;
    /* cursor: pointer; */
  }
  .row .col {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.9rem;
  }
  .features .feature-icon {
    height: 100px;
    width: 100px;
    border: 2px solid #eee;
    /* For Square instead of Circle, just commet out below border-radius */
    border-radius: 1000px;
    text-align: center;
    margin: 25px auto 0;
  }
  .features .feature-icon .material-icons {
    color: #1476f2;
    /* background-image: radial-gradient(circle, #1476f2, #1e56c2, #193995, #0c1e6a, #010142); */

    /* background: linear-gradient(90deg, #134758 50%, #d3d3d3 50%); */

    opacity: 0.85;
    /* font-size: 40px; */
    /* line-height: 96px; */
    width: auto;
    height: 72px;
    padding-top: 15px;

    &:hover {
      transform: scale(1.1);
    }
  }
  .features .feature-title {
    padding: 20px 4px 6px 4px;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    color: ${styles.colors.mainBlueColor};
  }
  .features .feature-description {
    text-align: left;
    color: ${styles.colors.mainSubtitleClr};
    /* color: rgba(50, 50, 50, 0.8); */
    padding-top: 10px;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 200;
    height: 150px;
    /* text-transform: capitalize; */

    /* color: #6c747f; */
    /* text-align: justify;
    text-justify: inter-word; */
  }
  /* @media (max-width: 880px) {
    .features .feature-title {
      font-size: 0.6rem !important;
    }
  } */
`;

export default BlockchainList;
