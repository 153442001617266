import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ServicesBody from "../components/02-services/ServicesBody";
import { ServicesHeader, BannerDefault } from "../utils";
// import serviceImg from "../utils/img/bcg/servicesbcg.jpg";
import { StaticQuery, graphql } from "gatsby";
import ContactList from "../components/06-contact/ContactList";

import styled from "styled-components";

/**************************************/
//          ServicesPage Class
/**************************************/
const ServicesPage = ({ dtitle, dhSlogan }) => (
  <Layout>
    <SEO
      title="XBound"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />
    <StaticQuery
      query={SERVICETITLE}
      render={data => {
        const servicesDetail = data.servicespage.edges[0];
        const { title, hSlogan } = servicesDetail.node;
        return (
          <ServiceWrapper>
            {/* <ReactCSSTransitionGroup
              transitionName="example"
              transitionEnterTimeout={1500}
              transitionLeaveTimeout={500}
            > */}
            {/* <ScrollAnimation animateIn="fadeIn"> */};
            {/* <ServicesHeader img={serviceImg}> */}
            <ServicesHeader>
              <BannerDefault
                title={title || dtitle}
                subtitle={hSlogan || dhSlogan}
              />
            </ServicesHeader>
            <ServicesBody servicesTitle={servicesDetail} />
            <ContactList />
            {/* </ScrollAnimation> */}
            {/* </ReactCSSTransitionGroup> */}
          </ServiceWrapper>
        );
      }}
    />
  </Layout>
);

const ServiceWrapper = styled.div`
  .example-enter,
  .example-leave {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  /* Transition #1: the old content leave */
  .example-leave {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
  .example-leave.example-leave--active {
    opacity: 0;
  }

  /* Transition #2: the new content enter */
  .example-enter {
    opacity: 0;
    transition: opacity 0.5s 0.5s ease-out;
  }
  .example-enter.example-enter--active {
    opacity: 1;
  }

  /* Transition #3: additional transition for the inner content */
  .example-enter .example-content {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    transition: opacity 0.5s 1s ease-out, transform 0.5s 1s ease-out;
  }
  .example-enter.example-enter--active .example-content {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
/**************************************/
//          Graphql Constants
/**************************************/

const SERVICETITLE = graphql`
  {
    servicespage: allContentfulServicesPage {
      edges {
        node {
          title
          hSlogan
          sectionTitle
          sectionSubtitle {
            sectionSubtitle
          }
          sFlowTitle
          sFlowDescription {
            sFlowDescription
          }
          sectionTitle2
          sectionSubtitle2 {
            sectionSubtitle2
          }
        }
      }
    }
  }
`;

ServicesPage.defaultProps = {
  dtitle: "Software development services",
  dhSlogan:
    "With a solid, proven-experience in the areas of design, development, and testing, We provide a wide range of services from consulting to development and auditing."
};
export default ServicesPage;
