import React, { Component } from "react";
import styled from "styled-components";
// import { styles } from "../../../utils/";
import { StaticQuery, graphql } from "gatsby";

export default class SlideServices extends Component {
  state = {
    activeIndex: 1,
    left: 0
  };
  render() {
    // const { item, index } = this.props;
    return (
      <SlidesInfoWrapper>
        <StaticQuery
          query={SERVICE}
          render={data => {
            const servicesDetail = data.serviceCards.edges;
            return (
              <ul className="ul-item">
                {servicesDetail.map((item, index) => {
                  const imgsrc = require(`../../../utils/icon/${
                    item.node.iconTitle
                  }.svg`);

                  return (
                    <li
                      className={
                        index + 1 === this.state.activeIndex
                          ? "li-item-active l3"
                          : "li-item"
                      }
                      key={index}
                    >
                      <div className="icon-title-container">
                        <div
                          className="icon-container"
                          onClick={e => {
                            this.setState({
                              activeIndex: index + 1
                            });
                          }}
                        >
                          <img
                            src={imgsrc}
                            className="icon"
                            alt={item.node.title}
                          />
                          <h3 className="icon-title">{item.node.title}</h3>
                        </div>
                      </div>

                      <div
                        className={
                          index + 1 === this.state.activeIndex
                            ? "descript-container-active"
                            : "descript-container"
                        }
                      >
                        <h3 className="descript-title">{item.node.title}</h3>
                        <p className="descript-subtitle">
                          {item.node.subtitle}
                        </p>
                        <p className="description-p">
                          {item.node.description.description}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            );
          }}
        />
      </SlidesInfoWrapper>
    );
  }
}

/**************************************/
//          Graphql Constants
/**************************************/
const SERVICE = graphql`
  {
    serviceCards: allContentfulServiceCard(
      sort: { fields: [index], order: ASC }
    ) {
      edges {
        node {
          index
          title
          subtitle
          description {
            description
          }
          iconTitle
        }
      }
    }
  }
`;

/************************************/
/** SlidesInfo Styled Component */
/************************************/
const SlidesInfoWrapper = styled.section`
  margin: 4rem 2rem 1.5rem 1.5rem;
  /*   overflow: visible|hidden|scroll|auto|initial|inherit; */
  overflow: hidden;
  text-align: left;
  height: auto;
  .icon-container {
    text-align: center;
  }
  .icon {
    width: auto;
    height: 80px;
  }
  .icon-title {
    text-align: center;
    margin-bottom: 0rem;
    margin-top: 0.9rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 1.2rem;
    text-transform: capitalize;
    color: #323232;
    /*  */
  }
  .descript-p {
    font-size: 1rem;
    line-height: 1.56rem;
    font-weight: 400;
    color: #323232;
  }

  .descript-subtitle {
    margin: 0.5rem 0rem;
    font-size: 1rem;
    text-transform: capitalize;
    line-height: 1.56rem;
    font-weight: 310;
    color: #aaa;
  }
  /* Small screens Breakpoint  */
  /* ************************* */
  @media (max-width: 767px) {
    ul {
      display: block;
      margin: 2rem auto;
    }
    li {
      display: block;
    }
    .descript-title {
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 1.2rem;
      font-weight: 600;
      text-align: center;
      display: none;
    }

    .descript-subtitle {
      text-align: center;
    }

    .icon-container {
      margin: 1.5rem 0rem;
    }
    .icon-title {
      margin-bottom: 1.4rem;
      font-weight: 600;
    }
  }

  /* Big screens Breakpoint  */
  /* ************************* */
  @media (min-width: 767px) {
    margin: 5.2rem 2rem 2rem 2rem;
    /* margin: 5rem 3rem 3rem 2rem; */
    ul {
      position: relative;
      display: flex;
      list-style-type: none;
      /* margin-bottom here is to gave a height of the component */
      margin-bottom: 22rem;
      -webkit-box-pack: justify;
      justify-content: center;
      align-items: center;
    }
    li {
      width: 100%;
      border-bottom: 1px solid rgba(50, 50, 50, 0.1);
    }
    .li-item-active {
      border-bottom: 1px solid #3f7fe7;
    }
    .icon-title-container {
      height: 10.5rem;
      display: grid;
      padding-left: 1.3rem;
      -webkit-box-align: center;
      align-items: center;
      padding-bottom: 30px;
      text-align: center;
      cursor: pointer;
    }
    .descript-container {
      position: absolute;
      margin-top: 2rem;
      padding-left: 1rem;
      /*to transit from right to left change left value to for example -115%*/
      /* display: none;
      overflow: hidden;*/
      left: 110%;
      width: 90%;
      /* overflow: scroll; */
    }
    /* When active I want to start the transation when ever "left" attribute will change */
    .descript-container-active {
      position: absolute;
      padding-left: 1.3rem;
      display: block;
      float: left;
      margin-top: 1rem 1rem;
      left: 0;
      width: 85%;
      transition: left 0.3s linear;
    }
    .descript-title {
      display: block;
      margin-bottom: 0.3rem;
      font-size: 1.1rem;
      line-height: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #323232;
    }
    .descript-container-active .descript-title {
      width: 50%;
    }
  }
`;
